import React from 'react';
import VisibilitySensor from 'react-visibility-sensor';
import { Header, PageLayout, Overlay, Sprite } from '../../components/index';
import { Link } from 'gatsby';
import { GridBox, GridSection } from '../../components/Grid';
import {
  contactDetails,
  pigeonLayout,
  patternsLayout,
  sectionHeight,
  form,
  flagLayout,
} from './layouts';
import { SimpleImage } from '../../helper/StyledHelper';
import { pigeonStates, flagStates } from './animations';
import { colors } from '../../helper/Variables';
import { Heading1, Heading2, Text } from '../../helper/Typography';
import ContactForm from '../../components/Forms/ContactForm';
import {
  transformLanguageString,
  mapMetaObject,
  getUrlFromId,
} from '../../helper/helperServices';
import PageEnhancer, { EntryPageProps } from '../../components/PageEnhancer';
import { SeoDataComponent } from '@lws/react-components';

class Contact extends PageEnhancer {
  constructor(props: EntryPageProps) {
    super(props);
    this.state = {
      pigeon: undefined,
      flag: undefined,
      darkTheme: false,
      overlay: {
        formState: undefined,
        requestType: undefined,
      },
    };
  }

  componentDidMount() {
    this.callSuperComponentDidMountMethods();
    this.setInitialAnimationsState(['pigeon', 'flag']);
  }

  toggleOverlay(formState: any, requestType: string) {
    this.setState({
      overlay: {
        formState,
        requestType,
      },
    });
  }

  render() {
    const {
      generalTexts,
      routeTexts: contact,
      locale,
    } = this.props.pageContext;

    return (
      <PageLayout
        userLanguage={locale}
        generalText={generalTexts}
        darkTheme={this.state.darkTheme}
      >
        <main className={this.state.darkTheme ? 'App Dark' : 'App Light'}>
          <Header
            darkTheme={this.state.darkTheme}
            lang={locale}
            pathname={this.props.location.pathname}
            generalTexts={generalTexts}
          />
          <SeoDataComponent data={mapMetaObject(contact.meta).data} />
          <GridSection gridRows={sectionHeight.form}>
            <GridBox layout={form.heading} style={{ alignContent: 'end' }}>
              <Heading1>{contact.form.title}</Heading1>
              <Text.p style={{ margin: 0 }}>{contact.form.subtitle}</Text.p>
            </GridBox>

            <GridBox layout={form.body}>
              <ContactForm
                contactForm={generalTexts.contactForm}
                requestType={'hello'}
                lang={transformLanguageString(locale)}
                toggleOverlay={(formState: any, requestType: string) =>
                  this.toggleOverlay(formState, requestType)
                }
                darkTheme={this.state.darkTheme}
              />
            </GridBox>

            {typeof this.state.pigeon !== 'undefined' && (
              <Sprite
                layout={pigeonLayout}
                name={'pigeon'}
                animationState={this.state.pigeon}
                setAnimationState={this.setAnimationState}
                isActive={true}
                isSticky={false}
                animationsDefinitions={pigeonStates}
              />
            )}

            <GridBox layout={patternsLayout.p1}>
              <SimpleImage
                src={'/assets/patterns/contact/stage/pattern-v1-01.svg'}
              />
            </GridBox>
            <GridBox layout={patternsLayout.p2} className={'showAbove-tablet1'}>
              <SimpleImage
                src={'/assets/patterns/contact/stage/pattern-v1-02.svg'}
              />
            </GridBox>
            <GridBox layout={patternsLayout.p3} translateTop={0.5}>
              <SimpleImage src={'/assets/patterns/contact/pattern-01.svg'} />
            </GridBox>
            <GridBox
              layout={patternsLayout.p4}
              translateTop={0.5}
              className={'showAbove-mobile2'}
            >
              <SimpleImage src={'/assets/patterns/contact/pattern-02.svg'} />
            </GridBox>
          </GridSection>

          {/*<GridSection gridRows={sectionHeight.image}>
            <FullWidthElement>
              <Image imageFolderUrl={'/assets/pages/contact/banner'} />
            </FullWidthElement>
          </GridSection>*/}
          <VisibilitySensor
            partialVisibility={true}
            minTopValue={450}
            onChange={isVisible =>
              requestAnimationFrame(() =>
                this.setState({ darkTheme: isVisible })
              )
            }
          >
            <div>
              <GridSection gridRows={sectionHeight.contactDetails}>
                <Sprite
                  layout={flagLayout}
                  name={'flag'}
                  animationState={this.state.flag}
                  setAnimationState={this.setAnimationState}
                  isActive={true}
                  isSticky={false}
                  translateTop={-0.5}
                  animationsDefinitions={flagStates}
                />
                <GridBox
                  style={{ alignContent: 'center' }}
                  layout={contactDetails.title}
                >
                  <Heading2 darkTheme={this.state.darkTheme}>
                    {contact.contactDetails.title}
                  </Heading2>
                </GridBox>
                <GridBox
                  style={{ alignContent: 'start' }}
                  layout={contactDetails.frankfurt.address}
                >
                  <Text.p
                    darkTheme={this.state.darkTheme}
                    style={{ marginBottom: '8px' }}
                  >
                    {contact.contactDetails.frankfurt.title}
                  </Text.p>
                  <Text.small style={{ color: colors.grey, margin: 0 }}>
                    {contact.contactDetails.companyName}
                  </Text.small>
                  <Text.small style={{ color: colors.grey, margin: 0 }}>
                    {contact.contactDetails.frankfurt.address}
                  </Text.small>
                  <Text.small style={{ color: colors.grey, margin: 0 }}>
                    {contact.contactDetails.frankfurt.postalCode}
                  </Text.small>
                </GridBox>
                <GridBox layout={contactDetails.frankfurt.link}>
                  <Text.small
                    style={{
                      color: this.state.darkTheme ? colors.white : colors.black,
                      textDecoration: 'underline',
                    }}
                  >
                    <Link
                      to={getUrlFromId(locale, 'jobs')}
                      state={{ city: 'Frankfurt am Main' }}
                    >
                      {contact.contactDetails.jobsLink}
                    </Link>
                  </Text.small>
                </GridBox>
                <GridBox
                  style={{ alignContent: 'start' }}
                  layout={contactDetails.berlin.address}
                >
                  <Text.p
                    darkTheme={this.state.darkTheme}
                    style={{ marginBottom: '8px' }}
                  >
                    {contact.contactDetails.berlin.title}
                  </Text.p>
                  <Text.small style={{ color: colors.grey, margin: 0 }}>
                    {contact.contactDetails.companyName}
                  </Text.small>
                  <Text.small style={{ color: colors.grey, margin: 0 }}>
                    {contact.contactDetails.berlin.address}
                  </Text.small>
                  <Text.small style={{ color: colors.grey, margin: 0 }}>
                    {contact.contactDetails.berlin.postalCode}
                  </Text.small>
                </GridBox>
                <GridBox layout={contactDetails.berlin.link}>
                  <Text.small
                    style={{
                      color: this.state.darkTheme ? colors.white : colors.black,
                      textDecoration: 'underline',
                    }}
                  >
                    <Link
                      to={getUrlFromId(locale, 'jobs')}
                      state={{ city: 'Berlin' }}
                    >
                      {contact.contactDetails.jobsLink}
                    </Link>
                  </Text.small>
                </GridBox>
              </GridSection>
            </div>
          </VisibilitySensor>
          <Overlay
            currentState={this.state.overlay.formState}
            requestType={this.state.overlay.requestType}
            contactForm={generalTexts.contactForm}
            toggleOverlay={(formState: any, requestType: string) =>
              this.toggleOverlay(formState, requestType)
            }
          />
        </main>
      </PageLayout>
    );
  }
}
export default Contact;
