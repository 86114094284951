import React from 'react';
import styled from 'styled-components';

export const FormLoadingOverlay = props => {
  return <WhiteOverlay isOpen={props.currentState === 'loading'} />;
};

const WhiteOverlay = styled.div`
  background-color: rgba(255, 255, 255, 0.6);
  position: absolute;
  bottom: 0;
  right: 0;
  overflow: hidden;
  z-index: 1000;
  left: ${props => (props.isOpen ? 0 : '100%')};
  top: ${props => (props.isOpen ? 0 : '100%')};
`;
