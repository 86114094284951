export const pigeonStates = [
  {
    id: '01_start',
    duration: 500,
    loop: false,
    amountOfFrames: 1,
    chainToState: 1,
  },
  {
    id: '02_loop',
    duration: 7400,
    loop: true,
    amountOfFrames: 162,
  },
];

export const flagStates = [
  {
    id: '01_loop',
    duration: 900,
    loop: true,
    amountOfFrames: 19,
  },
];
