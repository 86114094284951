import React from 'react';
import TextField from '@material-ui/core/TextField';
import withStyles from '@material-ui/core/styles/withStyles';

export const Input = props => {
  return props.multiline ? (
    <StyledMultiLineTextField
      fullWidth
      required
      label={props.label}
      id={props.name}
      name={props.name}
      type="text"
      multiline
      {...props}
    />
  ) : (
    <StyledTextField
      fullWidth
      required
      label={props.label}
      id={props.name}
      name={props.name}
      {...props}
    />
  );
};

const StyledMultiLineTextField = withStyles({
  root: {
    height: 'auto',
    marginBottom: '28px',

    '& label': {
      zIndex: '100',
      color: '#999999!important',
      //marginTop: '0',
      fontFamily: 'Metric-Regular, sans-serif',
      paddingTop: '10px',
      '&>span': {
        display: 'none',
      },
    },

    '&:focus': {
      label: {
        color: 'red',
      },
    },

    '&>div': {
      '&:before': {
        borderBottom: '#E0E0E0 1px solid',
      },
      '&:after': {
        borderBottom: 'black 2px solid',
      },
    },

    '& input': {
      zIndex: '10',
      paddingTop: '30px',
      fontFamily: 'Metric-Regular, sans-serif',
      '&:focus': {},
    },
    '& textarea': {
      zIndex: '10',
      lineHeight: '32px',
      marginTop: '30px',
      fontFamily: 'Metric-Regular, sans-serif',
      maxHeight: ' calc(32px * 4)',
      '&:focus': {},
    },
  },
})(TextField);

const StyledTextField = withStyles({
  root: {
    height: 84,
    marginBottom: '28px',

    '& label': {
      zIndex: '100',
      color: '#999999!important',
      marginTop: '0',
      fontFamily: 'Metric-Regular, sans-serif',
      paddingTop: '10px',
      '&>span': {
        display: 'none',
      },
    },

    '&:focus': {
      label: {
        color: 'red',
      },
    },

    '&>div': {
      '&:before': {
        borderBottom: '#E0E0E0 1px solid',
      },
      '&:after': {
        borderBottom: 'black 2px solid',
      },
    },

    '& input': {
      zIndex: '10',
      marginTop: '20px',
      fontFamily: 'Metric-Regular, sans-serif',
      '&:focus': {},
    },
  },
})(TextField);
