import React, { Fragment } from 'react';
import styled from 'styled-components';
import './selectStyles.css';
import withStyles from '@material-ui/core/styles/withStyles';
import FormControl from '@material-ui/core/FormControl/FormControl';
import InputLabel from '@material-ui/core/InputLabel/InputLabel';
import MenuItem from '@material-ui/core/MenuItem/MenuItem';
import { Select } from '@material-ui/core';
import { ErrorText } from './ContactForm';

const NGLBSelect = props => {
  const {
    contactForm,
    dontTranslate = false,
    clearMarginBottom = false,
    darkTheme = false,
    isEmpty,
    formErrors,
  } = props;
  const translatedOtions = contactForm
    ? props.name === 'requestTypeSelect'
      ? contactForm.subjectTranslation
      : contactForm.mediumTranslation
    : '';

  return (
    <Fragment>
      <StyledFormControl
        clearMarginBottom={clearMarginBottom}
        darkTheme={darkTheme}
        className={'formControl'}
      >
        <InputLabel htmlFor={props.name} shrink={true}>
          {props.label}
        </InputLabel>
        <StyledSelect
          value={props.value}
          onChange={props.onChange}
          inputProps={{
            name: props.name,
            id: props.name,
          }}
          darkTheme={darkTheme}
        >
          {props.options &&
            props.options.map(option => {
              const displayText = dontTranslate
                ? option
                : translatedOtions[option === '' ? 'empty' : option];
              return (
                <MenuItem key={option} value={option}>
                  {displayText}
                </MenuItem>
              );
            })}
        </StyledSelect>
      </StyledFormControl>
      {isEmpty && <ErrorText>{formErrors.required}</ErrorText>}
    </Fragment>
  );
};

export default NGLBSelect;

const StyledFormControl = styled(FormControl)`
  min-width: 100% !important;
  height: 84px;
  margin-bottom: ${({ clearMarginBottom }) =>
    clearMarginBottom ? '0px !important' : '28px !important'};

  & > div:before {
    border-bottom: #e0e0e0 1px solid;
  }
  & > div:after {
    border-bottom: 2px solid
      ${({ darkTheme }) => (darkTheme ? 'white' : 'black')};
  }
  .MuiSelect-select-25:focus {
    background-color: transparent;
  }

  .MuiSelect-icon {
    color: ${({ darkTheme }) => darkTheme && 'white'};
  }

  .MuiInput-underline:hover:not(.Mui-disabled):before {
    border-bottom: 2px solid
      ${({ darkTheme }) => (darkTheme ? 'white' : 'black')};
  }

  label {
    font-size: 22px;
    color: #999999 !important;
    z-index: 100;
    margin-top: 0;
    font-family: Metric-Regular, sans-serif;
    padding-top: 10px;
  }
`;

const StyledSelect = withStyles({
  root: {
    fontFamily: "'Metric-Regular',sans-serif",
    fontSize: '22px',
    lineHeight: '32px',
    color: ({ darkTheme }) => (darkTheme ? 'white' : 'black'),
    width: '100%',
    border: 0,
    margin: 0,
    padding: '6px 0 7px',
    display: 'block',
    minWidth: 0,
    boxSizing: 'content-box',
    background: 'none',
    marginTop: '30px',

    '&>div': {
      '&:before': {
        borderBottom: '#E0E0E0 1px solid',
      },
      '&:after': {
        borderBottom: 'black 2px solid',
      },
      '&:focus': {
        backgroundColor: 'transparent',
      },
    },
  },
})(Select);
