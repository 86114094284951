import React, { Component, Fragment } from 'react';
import styled from 'styled-components';

import { Input } from './Input';
import Dropzone from 'react-dropzone';
import { Text } from '../../helper/Typography';
import { Button } from '../../helper/StyledHelper';

import { CapitalizeString } from '../../helper/helperServices';
import { FormLoadingOverlay } from '../FormLoadingOverlay/FormLoadingOverlay';
import { colors } from '../../helper/Variables';
import NGLBSelect from './Select';
import { availableCountries } from './availableCountries';

class ContactForm extends Component {
  state = {
    // Set initial files
    formValues: {
      firstName: '',
      lastName: '',
      placeOfResidence: '',
      phone: '',
      email: '',
      portfolioLink: '',
      profileLink: '',
      message: '',
      requestType: this.props.requestType,
      acceptedFiles: [],
      rejectedFiles: [],
      acceptedFiles2: [],
      rejectedFiles2: [],
      medium: '',
      salaryExpectation: '',
      startDate: '',
      question1: '',
      question2: '',
      utmSource: '',
      utmMedium: '',
      utmCampaign: '',
      internalJobId: this.props.internalJobId,
    },
    formState: 'initial',
    hasError: false,
  };
  requestTypes = Object.keys(
    this.props.contactForm.subjectTranslation
  ).map(key => (key === 'empty' ? '' : key));
  mediums = Object.keys(this.props.contactForm.mediumTranslation).map(key =>
    key === 'empty' ? '' : key
  );

  checkSpecialInputs = () => {
    const specialInputs = ['placeOfResidence', 'medium', 'acceptedFiles'];
    this.setState({
      hasError: false,
    });
    specialInputs.map(input => {
      if (
        !this.state.formValues[input] ||
        !this.state.formValues[input].length
      ) {
        this.setState({
          hasError: true,
        });
      }
    });
  };

  onSubmit(e) {
    e.preventDefault();

    this.checkSpecialInputs();

    if (this.state.hasError) {
      return;
    }

    this.setState({
      formState: 'loading',
    });

    const location =
      this.props.location && CapitalizeString(this.props.location);
    const subject = () => {
      if (this.state.formValues.requestType === 'application') {
        return `Bewerbung von ${this.state.formValues.firstName} ${this.state.formValues.lastName} als ${this.props.position} in ${location}`;
      } else if (this.state.formValues.requestType === 'hello') {
        return `${this.state.formValues.firstName} ${this.state.formValues.lastName} will hallo sagen`;
      } else if (this.state.formValues.requestType === 'newBusiness') {
        return `${this.state.formValues.firstName} ${this.state.formValues.lastName} hat eine Projektanfrage`;
      } else if (
        this.state.formValues.requestType === 'applicationInitiative'
      ) {
        return `${this.state.formValues.firstName} ${this.state.formValues.lastName} hat eine Frage zu Jobs und Karriere`;
      }
    };

    const customQuestion1 =
      this.props.customQuestions && this.props.customQuestions.length
        ? this.props.customQuestions[0].question.question
        : '';
    const customQuestion2 =
      this.props.customQuestions && this.props.customQuestions.length > 1
        ? this.props.customQuestions[1].question.question
        : '';

    // building the data object to be send
    const data = new FormData();
    data.append('firstName', this.state.formValues.firstName);
    data.append('lastName', this.state.formValues.lastName);
    data.append('placeOfResidence', this.state.formValues.placeOfResidence);
    data.append('phone', this.state.formValues.phone);
    data.append('portfolioLink', this.state.formValues.portfolioLink);
    data.append('profileLink', this.state.formValues.profileLink);
    data.append('email', this.state.formValues.email);
    data.append('message', this.state.formValues.message);
    data.append('position', this.props.position);
    data.append('internalPosition', this.props.internalPosition);
    data.append('salaryExpectation', this.state.formValues.salaryExpectation);
    data.append('startDate', this.state.formValues.startDate);
    data.append('requestType', this.state.formValues.requestType);
    data.append(
      'question1',
      customQuestion1 + ' ' + this.state.formValues.question1
    );
    data.append(
      'question2',
      customQuestion2 + ' ' + this.state.formValues.question2
    );
    this.state.formValues.acceptedFiles &&
      this.state.formValues.acceptedFiles.forEach(file =>
        data.append('file[]', file, file.name)
      );
    data.append(
      'medium',
      this.props.contactForm.mediumTranslation[this.state.formValues.medium]
    );
    data.append('utmSource', this.state.formValues.utmSource);
    data.append('utmMedium', this.state.formValues.utmMedium);
    data.append('utmCampaign', this.state.formValues.utmCampaign);
    data.append('internalJobId', this.state.formValues.internalJobId);
    data.append('location', location);
    data.append('subject', subject());
    data.append('lang', this.props.lang);
    this.state.formValues.acceptedFiles2 &&
      this.state.formValues.acceptedFiles2.forEach(file =>
        data.append('file2[]', file, file.name)
      );

    // make the request
    fetch('/contactForm/', {
      method: 'POST',
      body: data,
    })
      .then(res => {
        document
          .getElementsByTagName('html')[0]
          .classList.add('overflow-hidden');
        if (res.status === 200) {
          this.setState(
            {
              formState: 'success',
            },
            () => this.resetFormState()
          );
        } else {
          this.setState(
            {
              formState: 'failure',
            },
            () => this.resetFormState()
          );
        }
      })
      .catch(err => {
        document
          .getElementsByTagName('html')[0]
          .classList.add('overflow-hidden');
        this.setState(
          {
            formState: 'failure',
          },
          () => this.resetFormState()
        );
      });
  }

  updateRequestType(e) {
    this.updateFormValues('requestType', e.target.value);
  }

  updateFormValues(key, value) {
    this.setState({
      formValues: {
        ...this.state.formValues,
        [key]: value,
      },
    });
  }

  updateFileList(fileObject, acceptedFileArr, rejectedFileArr) {
    let formValues = {
      ...this.state.formValues,
      [acceptedFileArr]: [
        ...this.state.formValues[acceptedFileArr],
        ...fileObject[acceptedFileArr].map(file => file),
      ],
      [rejectedFileArr]: [
        ...this.state.formValues[rejectedFileArr],
        ...fileObject[rejectedFileArr].map(file => file),
      ],
    };
    this.setState(
      {
        formValues,
      },
      () =>
        this.props.getDocumentsLength(
          this.state.formValues[acceptedFileArr].length +
            this.state.formValues[rejectedFileArr].length
        )
    );
  }

  deleteFile(type, f) {
    this.setState({
      formValues: {
        ...this.state.formValues,
        [type]: this.state.formValues[type].filter(
          file => file.name !== f.name
        ),
      },
    });
  }

  resetFormState() {
    this.props.toggleOverlay(
      this.state.formState,
      this.state.formValues.requestType
    );
    setTimeout(() => {
      if (this.state.formState === 'success') {
        // reset the form after success
        this.setState({
          formValues: {
            firstName: '',
            lastName: '',
            placeOfResidence: '',
            email: '',
            phone: '',
            portfolioLink: '',
            profileLink: '',
            message: '',
            requestType:
              this.state.formValues.requestType === 'application'
                ? 'application'
                : '',
            medium: '',
            salaryExpectation: '',
            startDate: '',
            question1: '',
            question2: '',
            acceptedFiles: [],
            rejectedFiles: [],
            acceptedFiles2: [],
            rejectedFiles2: [],
          },
        });
      }
      document
        .getElementsByTagName('html')[0]
        .classList.remove('overflow-hidden');
      this.props.toggleOverlay();
      this.setState({
        formState: 'initial',
      });
    }, 3000);
  }

  componentDidMount() {
    const params = new URLSearchParams(this.props.utmsStr);
    this.setState({
      formValues: {
        ...this.state.formValues,
        utmSource: params.get('utm_source'),
        utmMedium: params.get('utm_medium'),
        utmCampaign: params.get('utm_campaign'),
      },
    });
  }

  render() {
    const contactForm = this.props.contactForm;
    const formValues = this.state.formValues;

    const acceptedFileList =
      formValues &&
      formValues.acceptedFiles.map((f, idx) => (
        <FileListItem key={`file-${idx}`}>
          <FileName>{f.name}</FileName>
          <FileSize>{`${Math.round(f.size * 1e-6 * 10) / 10} MB`}</FileSize>
          <DeleteButton
            src={'/assets/icons/header/close.svg'}
            onClick={() => this.deleteFile('acceptedFiles', f)}
          />
        </FileListItem>
      ));

    const rejectedFileList =
      formValues &&
      formValues.rejectedFiles.map((f, idx) => (
        <RejectedFileListItem key={`file-${idx}`}>
          <FileName>{f.name}</FileName>
          <FileSize>{`${Math.round(f.size * 1e-6 * 10) / 10} MB`}</FileSize>
          <DeleteButton
            src={'/assets/icons/header/close.svg'}
            onClick={() => this.deleteFile('rejectedFiles', f)}
          />
        </RejectedFileListItem>
      ));

    const acceptedFileList2 =
      formValues.acceptedFiles2 &&
      formValues.acceptedFiles2.map((f, idx) => (
        <FileListItem key={`file-${idx}`}>
          <FileName>{f.name}</FileName>
          <FileSize>{`${Math.round(f.size * 1e-6 * 10) / 10} MB`}</FileSize>
          <DeleteButton
            src={'/assets/icons/header/close.svg'}
            onClick={() => this.deleteFile('acceptedFiles2', f)}
          />
        </FileListItem>
      ));

    const rejectedFileList2 =
      formValues.rejectedFiles2 &&
      formValues.rejectedFiles2.map((f, idx) => (
        <RejectedFileListItem key={`file-${idx}`}>
          <FileName>{f.name}</FileName>
          <FileSize>{`${Math.round(f.size * 1e-6 * 10) / 10} MB`}</FileSize>
          <DeleteButton
            src={'/assets/icons/header/close.svg'}
            onClick={() => this.deleteFile('rejectedFiles2', f)}
          />
        </RejectedFileListItem>
      ));

    return (
      <>
        <ContactFormHTML onSubmit={e => this.onSubmit(e)} id={'contactForm'}>
          {formValues.requestType !== 'application' && (
            <NGLBSelect
              contactForm={contactForm}
              label={contactForm.subject}
              name={'requestTypeSelect'}
              value={formValues.requestType}
              options={this.requestTypes}
              onChange={e => this.updateRequestType(e)}
            />
          )}
          <InlineInputContainer>
            <Input
              name={'firstName'}
              label={contactForm.firstName}
              value={formValues.firstName}
              style={{ marginRight: '10%' }}
              onChange={e =>
                this.updateFormValues(e.target.name, e.target.value)
              }
              /*error={(e) => formValues[e.target.name] !== ''}*/
            />
            <Input
              name={'lastName'}
              label={contactForm.lastName}
              value={formValues.lastName}
              onChange={e =>
                this.updateFormValues(e.target.name, e.target.value)
              }
              /*error={(e) => formValues[e.target.name] !== ''}*/
            />
          </InlineInputContainer>
          <InlineInputContainer>
            <Input
              name={'email'}
              type={'email'}
              label={contactForm.email}
              value={formValues.email}
              style={{ marginRight: '10%' }}
              onChange={e =>
                this.updateFormValues(e.target.name, e.target.value)
              }
            />
            {this.state.formValues.requestType === 'application' && (
              <Input
                name={'phone'}
                label={contactForm.phone}
                value={formValues.phone}
                onChange={e =>
                  this.updateFormValues(e.target.name, e.target.value)
                }
              />
            )}
          </InlineInputContainer>
          {this.state.formValues.requestType === 'application' && (
            <Fragment>
              <NGLBSelect
                contactForm={contactForm}
                label={contactForm.placeOfResidence}
                name={'placeOfResidence'}
                value={formValues.placeOfResidence}
                options={availableCountries}
                dontTranslate={true}
                clearMarginBottom={true}
                isEmpty={
                  !this.state.formValues.placeOfResidence && this.state.hasError
                }
                formErrors={this.props.formErrors}
                onChange={e =>
                  this.updateFormValues(e.target.name, e.target.value)
                }
              />
              <InlineInputContainer>
                <Input
                  name={'portfolioLink'}
                  label={contactForm.portfolioLink}
                  value={formValues.portfolioLink}
                  style={{ marginRight: '10%' }}
                  required={false}
                  onChange={e =>
                    this.updateFormValues(e.target.name, e.target.value)
                  }
                />
                <Input
                  name={'profileLink'}
                  label={contactForm.profileLink}
                  value={formValues.profileLink}
                  required={false}
                  onChange={e =>
                    this.updateFormValues(e.target.name, e.target.value)
                  }
                />
              </InlineInputContainer>
              <InlineInputContainer>
                <Input
                  name={'salaryExpectation'}
                  style={{ marginRight: '10%' }}
                  label={contactForm.salaryExpectation}
                  value={formValues.salaryExpectation}
                  onChange={e =>
                    this.updateFormValues(e.target.name, e.target.value)
                  }
                />
                <Input
                  name={'startDate'}
                  label={contactForm.startDate}
                  value={formValues.startDate}
                  onChange={e =>
                    this.updateFormValues(e.target.name, e.target.value)
                  }
                />
              </InlineInputContainer>
              {/* dropzone for CV */}
              <Dropzone
                accept=".pdf"
                maxSize={8e6}
                onDrop={(acceptedFiles, rejectedFiles) => {
                  (acceptedFiles.length > 0 || rejectedFiles.length > 0) &&
                    this.updateFileList(
                      {
                        acceptedFiles,
                        rejectedFiles,
                      },
                      'acceptedFiles',
                      'rejectedFiles'
                    );
                }}
              >
                {({ getRootProps, getInputProps, isDragActive }) => {
                  return (
                    <DropZone {...getRootProps()} isActive={isDragActive}>
                      <input
                        type={'file'}
                        name={'file'}
                        id={'upload'}
                        {...getInputProps()}
                      />
                      {isDragActive ? (
                        <Text.pGrey>{contactForm.dragActive}</Text.pGrey>
                      ) : (
                        <Text.pGrey>{contactForm.upload}</Text.pGrey>
                      )}
                    </DropZone>
                  );
                }}
              </Dropzone>
              {!acceptedFileList.length && this.state.hasError && (
                <ErrorText resetMargin>
                  {this.props.formErrors.required}
                </ErrorText>
              )}
              {rejectedFileList.length > 0 && (
                <Fragment>
                  <FileList>{rejectedFileList}</FileList>
                  <ErrorText>{this.props.formErrors.upload}</ErrorText>
                </Fragment>
              )}
              {acceptedFileList.length > 0 && (
                <FileList>{acceptedFileList}</FileList>
              )}
              {/* dropzone for sonstiges */}
              <Dropzone
                accept=".pdf"
                maxSize={8e6}
                onDrop={(acceptedFiles2, rejectedFiles2) => {
                  (acceptedFiles2.length > 0 || rejectedFiles2.length > 0) &&
                    this.updateFileList(
                      {
                        acceptedFiles2,
                        rejectedFiles2,
                      },
                      'acceptedFiles2',
                      'rejectedFiles2'
                    );
                }}
              >
                {({ getRootProps, getInputProps, isDragActive }) => {
                  return (
                    <DropZone
                      {...getRootProps()}
                      isActive={isDragActive}
                      style={{ marginBottom: '30px' }}
                    >
                      <input
                        type={'file'}
                        name={'file2'}
                        id={'upload'}
                        {...getInputProps()}
                      />
                      {isDragActive ? (
                        <Text.pGrey>{contactForm.dragActive}</Text.pGrey>
                      ) : (
                        <Text.pGrey>{contactForm.uploadOthers}</Text.pGrey>
                      )}
                    </DropZone>
                  );
                }}
              </Dropzone>
              {rejectedFileList2.length > 0 && (
                <Fragment>
                  <FileList>{rejectedFileList2}</FileList>
                  <ErrorText>
                    Something went wrong, please consider the maximum filesize
                    of 8MB
                  </ErrorText>
                </Fragment>
              )}
              {acceptedFileList2.length > 0 && (
                <FileList>{acceptedFileList2}</FileList>
              )}
              {/* custom questions */}
              {this.props.customQuestions &&
                this.props.customQuestions.map(({ question }, idx) => (
                  <Input
                    key={idx}
                    name={`question${idx + 1}`}
                    label={question.question}
                    value={formValues[`question${idx + 1}`]}
                    multiline={true}
                    onChange={e => {
                      this.updateFormValues(e.target.name, e.target.value);
                    }}
                  />
                ))}
            </Fragment>
          )}
          {this.state.formValues.requestType !== 'application' && (
            <Input
              name={'message'}
              label={contactForm.message}
              multiline={true}
              value={formValues.message}
              onChange={e =>
                this.updateFormValues(e.target.name, e.target.value)
              }
            />
          )}
          {this.state.formValues.requestType === 'application' && (
            <>
              <NGLBSelect
                contactForm={contactForm}
                label={contactForm.medium}
                name={'medium'}
                value={formValues.medium}
                options={this.mediums}
                clearMarginBottom={true}
                isEmpty={!this.state.formValues.medium && this.state.hasError}
                formErrors={this.props.formErrors}
                onChange={e =>
                  this.updateFormValues(e.target.name, e.target.value)
                }
              />
            </>
          )}
          <BottomSection>
            {this.state.formValues.requestType === 'application' && (
              <Disclaimer>
                <Text.small>
                  {contactForm.disclaimerText1}
                  <a
                    target="_blank"
                    rel="noopener noreferrer"
                    href={
                      this.props.lang === 'de'
                        ? `https://neugelb.com/de/datenschutz/`
                        : `https://neugelb.com/en/data-protection/`
                    }
                  >
                    {contactForm.disclaimerLinkText}
                  </a>
                  {contactForm.disclaimerText2}
                </Text.small>
              </Disclaimer>
            )}
            <Button
              type={'submit'}
              darkTheme={this.props.darkTheme}
              onClick={this.checkSpecialInputs}
              style={{
                marginTop:
                  this.state.formValues.requestType === 'application'
                    ? 0
                    : '30px',
              }}
            >
              {this.state.formState === 1
                ? contactForm.submitText
                : contactForm.sending}
            </Button>
            {this.state.formState === 'loading' && (
              <LoadingIndicator src={'/assets/icons/loading.gif'} />
            )}
          </BottomSection>
          <FormLoadingOverlay
            currentState={this.state.formState}
            requestType={this.state.formValues.requestType}
          />
        </ContactFormHTML>
      </>
    );
  }
}

export default ContactForm;

const ContactFormHTML = styled.form`
  position: relative;
`;
const BottomSection = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: baseline;
  @media (max-width: 479px) {
    flex-direction: column;
    align-items: flex-start;
    button {
      margin-top: 20px;
    }
  }
`;
const Disclaimer = styled(Text.small)`
  padding-top: 10px;
  width: calc(100% - 170px);
  @media (max-width: 479px) {
    width: 100%;
  }
`;

const DropZone = styled.div`
  margin-top: 30px;
  width: 100%;
  height: 160px;
  box-sizing: border-box;
  background-color: rgba(0, 0, 0, 0.03);
  border: 2px dotted ${colors.grey};
  border: 2px dashed ${colors.grey};
  cursor: pointer;
  p {
    padding: 60px 0;
    text-align: center;
    color: ${colors.grey};
  }
  &:focus {
    outline: none;
  }
`;
const FileList = styled.ul`
  width: 100%;
  list-style: none;
  padding: 0;
  &:last-of-type {
    margin: 0 0 50px 0;
  }
`;
const FileListItem = styled.li`
  width: 100%;
  display: block;
  height: 44px;
  margin: 8px 0 0 0;
  position: relative;
  padding: 10px 16px;
  box-sizing: border-box;
  background-color: ${colors.greyLighter};
`;
const RejectedFileListItem = styled(FileListItem)`
  border: 2px solid ${colors.red};
  padding: 8px 16px;
`;
const FileName = styled(Text.small)`
  display: block;
  float: left;
  color: ${colors.black};
  margin-right: 16px;
  margin-bottom: 0;
  max-width: 60%;
  max-height: 100%;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
`;
const FileSize = styled(Text.small)`
  color: ${colors.grey};
  margin-bottom: 0;
  display: block;
  float: left;
`;
export const ErrorText = styled(Text.small)`
  color: ${colors.red};
  margin-top: ${props => (props.resetMargin ? '0px' : '-16px')};
  margin-bottom: 32px;
`;
const DeleteButton = styled.img`
  position: absolute;
  cursor: pointer;
  right: 16px;
  top: 50%;
  height: 14px;
  transform: translateY(-50%);
`;
const LoadingIndicator = styled.img`
  z-index: 210;
  width: 30px;
  float: right;
  margin: 15px 20px 0 0;
`;

const InlineInputContainer = styled.div`
  display: flex;
  @media (max-width: 478px) {
    flex-direction: column;
  }
`;
