// BPS:
// 2 - mobile1: 0 - 320
// 2 - mobile2: 321 - 479
// 3 - mobile3: 480 - 767
// 4 - tablet1: 768 - 1023
// 5 - tablet2: 1024 - 1279
// 6 - desktop1: 1280 - 1599
// 7 - desktop2: 1600 - 1919
// 8 - desktop3: 1920 - 2239
// 9 - desktop4: 2240 - x

export const sectionHeight = {
  form: [12, 12, 12, 14, 11, 9, 9, 11, 11],
  image: [6, 6, 6, 4, 4, 4, 4, 4, 4],
  contactDetails: [6, 6, 6, 4, 4, 4, 4, 4, 4],
};

export const form = {
  heading: {
    //bps:      [1, 2, 3, 4, 5, 6, 7, 8, 9]
    top: [4, 4, 4, 5, 3, 2, 2, 4, 4],
    left: [1, 1, 1, 2, 3, 3, 4, 5, 6],
    boxWidth: [3, 3, 4, 4, 4, 4, 4, 4, 4],
    boxHeight: [2, 2, 2, 3, 2, 2, 2, 2, 2],
  },
  body: {
    //bps:      [1, 2, 3, 4, 5, 6, 7, 8, 9]
    top: [7, 7, 7, 9, 6, 5, 5, 7, 7],
    left: [1, 1, 1, 2, 3, 3, 4, 5, 6],
    boxWidth: [3, 3, 4, 4, 4, 4, 4, 4, 4],
    boxHeight: [2, 2, 2, 2, 2, 2, 2, 2, 4],
  },
};

export const contactDetails = {
  title: {
    top: [2, 2, 2, 2, 2, 2, 2, 2, 2],
    left: [1, 1, 1, 2, 2, 2, 3, 4, 5],
    boxWidth: [3, 3, 4, 3, 3, 2, 2, 2, 2],
  },
  frankfurt: {
    address: {
      top: [3, 3, 3, 3, 3, 3, 3, 3, 3],
      left: [1, 1, 1, 2, 2, 2, 3, 4, 5],
      boxWidth: [3, 3, 2, 2, 2, 1, 1, 1, 1],
      // boxHeight:  [2, 2, 2, 2, 2, 2, 2, 2, 2],
    },
    link: {
      top: [4, 4, 4, 4, 4, 4, 4, 4, 4],
      left: [1, 1, 1, 2, 2, 2, 3, 4, 5],
      boxWidth: [2, 2, 2, 2, 2, 2, 2, 2, 2],
      // boxHeight:  [2, 2, 2, 2, 2, 2, 2, 2, 2],
    },
  },
  berlin: {
    address: {
      top: [5, 5, 5, 3, 3, 3, 3, 3, 3],
      left: [1, 1, 1, 5, 5, 5, 6, 7, 8],
      boxWidth: [3, 3, 2, 2, 2, 1, 1, 1, 1],
      // boxHeight:  [2, 2, 2, 2, 2, 2, 2, 2, 2],
    },
    link: {
      top: [6, 6, 6, 4, 4, 4, 4, 4, 4],
      left: [1, 1, 1, 5, 5, 5, 6, 7, 8],
      boxWidth: [2, 2, 2, 2, 2, 2, 2, 2, 2],
      // boxHeight:  [2, 2, 2, 2, 2, 2, 2, 2, 2],
    },
  },
};

export const pigeonLayout = {
  //bps:      [1, 2, 3, 4, 5, 6, 7, 8, 9]
  top:        [2, 2, 2, 2, 2, 2, 2, 3, 3],
  left:       [2, 2, 3, 4, 8, 8, 9, 10, 11],
};
export const flagLayout = {
  //bps:      [1, 2, 3, 4, 5, 6, 7, 8, 9]
  top:        [1, 1, 1, 1, 1, 1, 1, 1, 1],
  left:       [1, 1, 1, 5, 6, 7, 8, 9, 10],
};

export const patternsLayout = {
  p1: {
    top:      [3, 3, 3, 3, 3, 3, 3, 4, 4],
    left:     [3, 3, 4, 5, 8, 8, 9, 10, 11],
  },
  p2: {
    top: [4, 4, 4, 6, 6, 5, 5, 7, 7],
    left: [1, 1, 1, 1, 1, 1, 2, 3, 4],
  },
  p3: {
    top: [11, 12, 11, 13, 10, 8, 8, 10, 10],
    left: [1, 1, 1, 1, 1, 1, 2, 3, 4],
  },
  p4: {
    top: [12, 12, 12, 14, 11, 9, 9, 11, 11],
    left: [1, 1, 1, 1, 1, 1, 2, 3, 4],
  },
  p5: {
    top: [3, 3, 3, 3, 3, 3, 3, 3, 3],
    left: [2, 2, 2, 2, 2, 2, 2, 2, 2],
  },
};
